import React, {memo, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Handle, Position} from "reactflow";

function ActionNode({id, data}){
    const {t} = useTranslation();
    const [actionType, setActionType] = useState("webhook");

    return <div style={{background:"white", border:"3px solid #0277BD", color:"#212121", borderRadius:7, padding:10}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginBottom:7}}>
            <h6 style={{color:"#212121", padding:0, margin:0}}>Action</h6>
            <a href="#!" style={{color:"#212121"}}><i className="material-icons">close</i></a>
        </div>
        <Form.Select value={actionType} onChange={e => setActionType(e.target.value)}>
            <option value="webhook">{t("webhook")}</option>

            <option value="insert_table">{t("insert_table")}</option>
            <option value="update_table">{t("update_table")}</option>
            <option value="delete_table">{t("delete_table")}</option>

            <option value="send_email">{t("send_email")}</option>
            <option value="send_sms">{t("send_sms")}</option>
        </Form.Select>

        {actionType === "webhook" && <Form.Group>
            <Form.Label style={{color:"#212121"}}>{t("url")}</Form.Label>
            <Form.Control type="text" name="url"/>
        </Form.Group>}

        <Handle type="target" position={Position.Left} id={0} />
        <Handle type="source" position={Position.Right} id={0} />
    </div>
}

export default memo(ActionNode);