import React, {memo, useState} from 'react';
import {Handle, Position} from "reactflow";
import {Button, Form, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function DecisionNode({id, data}){
    const {t} = useTranslation();

    const [conditions, setConditions] = useState([]);

    const AddCondition = ()=>{
         setConditions(conditions => [...conditions, {}])
    }
    return <div style={{background:"white", border:"3px solid #FF5722", color:"#212121", borderRadius:7, padding:10}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginBottom:7}}>
            <h6 style={{color:"#212121", padding:0, margin:0}}>Decision</h6>
            <a href="#!" style={{color:"#212121"}}><i className="material-icons">close</i></a>
        </div>
        <Table>
            <tbody>
            {conditions.map(cond => <tr>
                <td>
                    <Form.Select>
                        <option value="=">{"=="}</option>
                        <option value="!=">{"!="}</option>
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                        <option value=">">{">"}</option>
                        <option value="<">{"<"}</option>
                        <option value="LIKE">{"Like"}</option>
                    </Form.Select>
                </td>
                <td><Handle type="source" position={Position.Right} id={1}/></td>
            </tr>)}
            </tbody>
        </Table>
        <div className="d-grid gap-2">
        <Button onClick={AddCondition}>{t("add")}</Button>
        </div>
        <Handle type="source" position={Position.Left} id={0} />
    </div>
}

export default memo(DecisionNode);