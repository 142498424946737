import React, { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./forget.style.css";
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {fetchForgetPasswordConfirm} from "../../../repositories/login.api";

function ForgetConfirm() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    async function Submit(e) {
        e.preventDefault();
        setLoading(true)
        try {
            const {password, re_password} = Object.fromEntries(
                new FormData(e.target).entries()
            );

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let token = params.get('token');

            await fetchForgetPasswordConfirm(token, password, re_password);
            navigate('/login')

        } catch (e) {
            if (e.response) {
                setErr(e.response.data.msg);
            } else {
                setErr("Cant connect to server");
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Row style={{height: "100%"}}>
            <Col sm={12} lg={{span: 4, offset: 4}} style={{height: "100%"}}>
                <Row className="right-section align-items-center justify-content-center" style={{marginTop:40}}>
                    <h3 className="mb-1">{t("restore_password")}</h3>
                    {err !== "" &&
                        <Card className="mb-3" bg="danger">
                            <Card.Body>
                                {err}
                            </Card.Body>
                        </Card>
                    }
                    <Form onSubmit={Submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("new_password")}</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("re_new_password")}</Form.Label>
                            <Form.Control
                                type="password"
                                name="re_password"
                                required
                            />
                        </Form.Group>

                        <div className="d-grid gap-2 mb-3">
                            <Button variant="primary" type="submit" size="lg" disabled={loading}>
                                {loading ?
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                    />
                                    :
                                    <>
                                        <b>{t("submit")}</b>
                                        <i className="fa-solid fa-arrow-right center"></i>
                                    </>
                                }
                            </Button>
                        </div>
                        <div className="mb-3">
                            <Link to="/login">
                                {t("login_alert")}
                            </Link>
                        </div>
                    </Form>
                </Row>
            </Col>
        </Row>
    );
}

export default ForgetConfirm;