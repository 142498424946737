import React, {memo, useState} from 'react';
import {Handle, Position} from "reactflow";
import {Button, ButtonGroup, Form, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function TriggerNode({id, data}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    return <>
        <div style={{background: "white", border: "3px solid #7CB342", color: "#212121", borderRadius: 7, padding: 10}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginBottom:7}}>
                <h6 style={{color:"#212121", padding:0, margin:0}}>Trigger</h6>
                <a href="#!" style={{color:"#212121"}}><i className="material-icons">close</i></a>
            </div>
            <Form.Select>
                <option value="webhook">{t("webhook")}</option>
                <option value="on_score_submit">{t("on_score_submit")}</option>
                <option value="on_user_register">{t("on_user_register")}</option>
                <option value="scheduled">{t("scheduled")}</option>
                <option value="interval">{t("interval")}</option>
            </Form.Select>
            <Handle type="source" position={Position.Right} id={1}/>
        </div>
    </>
}

export default memo(TriggerNode);