import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {EnableService} from "../../../../../repositories/table.api";
import {Button, ButtonGroup, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {LimitLong} from "../../../../../utils/format";
import moment from "moment/moment";
import {LoadingContainer} from "../../../../../components/loading.component";

function JourneyList(){
    const {t} = useTranslation();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [serviceEnable, setServiceEnable] = useState(true);
    const [showDeleteJourneyModal, setShowDeleteJourneyModal] = useState({availability:false});

    useEffect(() => {
        getJourneys();
    }, [params.id]);

    const getJourneys = async () => {
        setLoading(true);
        try {
            // let {list, count} = await fetchGameAchievements(params.id);
            // setList(list);
            // setCount(count);
            // setServiceEnable(true)
        } catch (e) {
            if (e.response && e.response.data.msg === "table_notfound") {
                setServiceEnable(false)
            }
        }finally {
            setLoading(false);
        }
    }

    const enableService = async () => {
        await EnableService(params.id, "journey")
        await getJourneys()
    }

    if(loading) return <LoadingContainer style={{marginTop: 40}}/>
    //
    // if (serviceEnable)
    //     return <Container>
    //         <Col sm={12}>
    //             <div className="empty-banner">
    //                 <div style={{margin: 10}}>
    //                     <img src="/images/panel-icons/journey.png"/>
    //                     <h4>{t("journey_service_is_not_enabled")}</h4>
    //                     <span style={{margin: 7, display: 'block'}}>{t("enable_journey_service_note")}</span>
    //                 </div>
    //                 <Button variant="success" onClick={enableService}>{t("enable_journey_service")}</Button>
    //             </div>
    //         </Col>
    //     </Container>

    return <>
        <div className="mb-4" style={{backgroundColor: 'black'}}>
            <Container>
                <Row>
                    <Col style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <h3 style={{padding: "2em 0"}}>{t("journey")}</h3>
                    </Col>
                    <Col style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        alignItems: 'center'
                    }}>
                        <Button as={Link}
                                to={`/console/game/${params.id}/journey/new`}>{t("new_journey")}</Button>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row>
                {count < 1 && <Col sm={12}>
                    <div className="empty-banner mb-3">
                        <div style={{margin: 10}}>
                            <img src="/images/panel-icons/journey.png"/>
                            <h5>{t("journey_notfound")}</h5>
                            <span style={{margin: 7, display: 'block'}}>{t("no_journey_note")}</span>
                        </div>
                        <Button variant="link" href={t("journey_doc_link")}>{t("read_more_doc")}</Button>
                    </div>
                </Col>}

                {list.map((item, inx) => <Col sm={12} md={6} lg={3} key={item.id}>
                    <Card className="table-item selectable mb-3">
                        <a href={`/console/game/${params.id}/journey/${item.id}`}>
                            <Card.Body style={{textAlign:'center'}}>
                                <h5><b style={{color:"#EEE"}}>#{item.id}</b></h5>
                                <h4 style={{margin:3}}>
                                    {LimitLong(item.name, 20)}
                                </h4>
                                <p style={{textAlign:'center'}}>{LimitLong(item.desc, 32)}</p>

                                <Row>
                                    <Col className="box-div">
                                        <b>{t("start_at")}</b>
                                        <br/>
                                        <small>{item.start_at ? new moment(item.start_at).format("YYYY-MM-DD HH:mm") : "Without timing"}</small>
                                    </Col>
                                    <Col className="box-div">
                                        <b>{t("end_at")}</b>
                                        <br/>
                                        <small>{item.end_at ? new moment(item.end_at).format("YYYY-MM-DD HH:mm") : "Without timing"}</small>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </a>
                        <Card.Footer>
                            <ButtonGroup>
                                <Button onClick={() =>setShowDeleteJourneyModal({availability:true, journeyId:item.id})}>
                                    <i className="fa-solid fa-trash"></i>
                                </Button>
                            </ButtonGroup>
                        </Card.Footer>
                    </Card>
                </Col>)}


            </Row>
        </Container>
    </>
}

export default JourneyList;