import axios from "axios";
import { baseUrl, serviceUrl } from "../utils/consts.js";
import store from '../reducers/index';

export async function fetchTableRows(gameId, tableId, conditions, sort, skip = 0, limit = 25) {
    let {token} = store.getState().auth;

    try {
        let {data} = await axios.post(`${baseUrl}/game/${gameId}/table/${tableId}?skip=${skip}&limit=${limit}`,
            {
                conditions,
                sort: sort
            },{
                headers: {
                    "x-access-token": token,
                }
            }
        )
        return data.data;
    } catch (e) {
       throw e
    }
}

export async function checkTableHealth(token, tableId) {
    try {
        let {data} = await axios.post(`${serviceUrl}/table/${tableId}/check`,
            {},
            {
                headers: {
                    "x-access-token": token,
                }
            }
        )
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function fetchGameTables (gameId, filter) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.get(
            `${baseUrl}/game/${gameId}/table?filter=${filter}`
            , {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function fetchActiveServices (gameId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.get(
            `${baseUrl}/game/${gameId}/table/services`
            , {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data;
    } catch (e) {
        throw e;
    }
}

export async function CreateTables(gameId, table) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.post(
            `${baseUrl}/game/${gameId}/table`, table,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function EnableService(gameId, serviceName) {
    let {token} = store.getState().auth;

    try {
        let { data } = await axios.post(
            `${baseUrl}/game/${gameId}/table/services`
            , {
                serviceName: serviceName.toLowerCase()
            },
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function FetchCompatibleTables(gameId, col_name) {
    let {token} = store.getState().auth;

    try {
        let { data } = await axios.post(
            `${baseUrl}/game/${gameId}/table/extend`
            , {
                col_name: col_name
            },
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.list;
    } catch (e) {
        throw e;
    }
}

export async function InsertRow(gameId, tableId, newRow) {
    let {token} = store.getState().auth;
    try {
        await axios.post(`${baseUrl}/game/${gameId}/table/${tableId}/row`, {row: newRow}, {
            headers: {
                "x-access-token": token,
            }
        });
    } catch (e) {
        throw e
    }
}


export async function UpdateRow(gameId, tableId, rowId, newRow) {
    let {token} = store.getState().auth;
    try {
        await axios.put(`${baseUrl}/game/${gameId}/table/${tableId}/row/${rowId}`, {row: newRow}, {
            headers: {
                "x-access-token": token,
            }
        });
    } catch (e) {
        throw e;
    }
}

export async function DeleteRow(gameId,tableId, rowId) {
    let {token} = store.getState().auth;
    try {
        await axios.delete(`${baseUrl}/game/${gameId}/table/${tableId}/row/${rowId}`, {
            headers: {
                "x-access-token": token,
            }
        })
    } catch (e) {
        throw e
    }
}

export async function DeleteRows(gameId,tableId, rowsIds) {
    let {token} = store.getState().auth;
    try {
        await axios.put(`${baseUrl}/game/${gameId}/table/${tableId}/row`, {ids:rowsIds}, {
            headers: {
                "x-access-token": token,
            }
        })
    } catch (e) {
        throw e
    }
}

export async function DeleteAll(gameId, tableId) {
    let {token} = store.getState().auth;
    try {
        await axios.delete(`${baseUrl}/game/${gameId}/table/${tableId}`,{
            headers: {
                "x-access-token": token,
            }
        })
    } catch (e){
        throw e
    }
}

export async function SetTableSchema(gameId, tableId, name, newSchema) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.put(
            `${baseUrl}/game/${gameId}/table/${tableId}/schema`,
            {name, schema: newSchema},
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function SetTablePermission(gameId, tableId, newPermission) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.put(
            `${baseUrl}/game/${gameId}/table/${tableId}/permission`,
            newPermission,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function DeleteTable(gameId, tableId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.delete(
            `${baseUrl}/game/${gameId}/table/${tableId}`,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );
        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function ExportTable(gameId, tableId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.get(
            `${baseUrl}/game/${gameId}/table/${tableId}/backup`,
            {
                headers: {
                    "x-access-token": token,
                },
                responseType: 'blob',
            }
        );

        const href = URL.createObjectURL(data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${tableId}-export-${Date.now().toString()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    } catch (e) {
        throw e;
    }
}

export async function ImportTable(gameId, tableId, selectedFile) {
    let {token} = store.getState().auth;
    try {
        const formData = new FormData();
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );

        await axios.post(
            `${baseUrl}/game/${gameId}/table/${tableId}/backup`,
            formData,
            {
                headers: {
                    "x-access-token": token,
                },
                responseType: 'blob',
            }
        );
    } catch (e) {
        throw e;
    }
}