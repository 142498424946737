import {Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Condition from "./condition.component";
import {useState} from "react";

function Join({index, tables}){
    const {t} = useTranslation();
    const [table, setTable] = useState({rows: []});
    const [condition, setCondition] = useState([{key:"root", depth:0,  field:"id", operation:"==", value:""}]);

    const selectTable = async (e) => {
        let selectedTable = tables.find(t => t._id === e.target.value)
        setTable(selectedTable)
    }

    return <Row className="mb-5" key={`join-${index}`}>
        <Col className="mb-3" sm={12}>
            <Form.Group>
                <Form.Label>{t("joining_table")}</Form.Label>
                <Form.Select onChange={selectTable}>
                    {tables.map(table => <option value={table._id}>{table.name}</option>)}
                </Form.Select>
            </Form.Group>
        </Col>

        <div className="mb-5">
            {condition.map((cond, inx) => <Col sm={{ span: 12 - cond.depth, offset: cond.depth }} key={`cond-${inx}`} className="mb-3">
                <Condition
                    inx={inx}
                    table={table}
                    condition={cond}
                    deleteCondition={i => {
                        setCondition(conds => conds.filter((c, indx) => indx !== i))
                    }}
                    onChange={(field, value) => setCondition(conds => {
                        conds[inx] = {...conds[inx], [field] : value}
                        return [...conds]
                    })}
                />
                {(cond.operation === "AND" || cond.operation === "OR") && <Col sm={{span: 11, offset:1}} className="d-grid gap-2">
                    <Button onClick={()=>{
                        setCondition(conds => {
                            return [...conds.slice(0, inx+1), {depth: cond.depth + 1, key:`${cond.key}-${conds.length+1}`, parent: cond.key, field:"id", operation:"==", value:""}, ...conds.slice(inx+1)]
                        })
                    }}><i className="material-icons">add</i>{cond.key}</Button>
                </Col>}
            </Col>)}
        </div>
    </Row>
}

export default Join