import {useTranslation} from "react-i18next";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

function Footer() {
    const {t} = useTranslation();

    return <>
        <footer style={{borderTop: "1px solid #666", padding: 20}}>
            <Container>
                <Row style={{paddingTop: 30}}>
                    <Col sm={12} lg={3} className="logo-box d-flex flex-column mb-5">
                        <img src="/images/logo.svg" alt="dynamicpixels logo" className="footer-logo"/>
                        <ul className="links">
                            <li><a href="https://stackoverflow.com/questions/tagged/dynamicpixels"><i className="fa-brands fa-stack-overflow"></i></a></li>
                            <li><a href="https://github.com/DynamicPixels"><i className="fa-brands fa-github"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/dynamicpixelscloud"><i className="fa-brands fa-linkedin"></i></a></li>
                            <li><a href="https://discord.gg/RHMQNSTG8m"><i className="fa-brands fa-discord"></i></a></li>
                            <li><a href="https://twitter.com/DynamicpixelsBV"><i className="fa-brands fa-x-twitter"></i></a></li>
                        </ul>
                        <small>Made with 🤍 in Netherlands © 2023</small>
                    </Col>
                    <Col sm={12} lg={3}>
                        <h5>{t("navigate")}</h5>
                        <ul>
                            <li><Link to="/login">{t("developer_console")}</Link></li>
                            <li><Link to="https://docs.dynamicpixels.dev">{t("documentation")}</Link></li>
                            <li><Link to="/terms">{t("terms_of_use")}</Link></li>
                            <li><Link to="/policies">{t("privacy_policy")}</Link></li>
                            <li><Link to="/contact">{t("contact_us")}</Link></li>
                        </ul>
                    </Col>
                    <Col sm={12} lg={3}>
                        <h5>{t("learn_more")}</h5>
                        <ul>
                            <li><a href="https://docs.dynamicpixels.dev">{t("getting_started")}</a></li>
                            <li><a href="https://docs.dynamicpixels.dev/setup-plugin">{t("setup_plugins")}</a></li>
                            <li><a href="https://docs.dynamicpixels.dev/category/base-services">{t("basic_services")}</a></li>
                        </ul>
                    </Col>
                    <Col sm={12} lg={3}>
                        <h5>{t("status")}</h5>

                        <iframe src="https://status.dynamicpixels.dev/badge?theme=dark" title="dynamicpixels uptime status" width="250" height="50" frameBorder="0" scrolling="no"></iframe>                    </Col>
                </Row>
            </Container>
        </footer>
    </>
}

export default Footer;