import React, {memo} from 'react';
import {Handle, Position} from "reactflow";

function EndNode({id, data}){
    return <div style={{background:"white", border:"3px solid #8E24AA", color:"#212121", borderRadius:7, padding:10}}>
        <span>End</span>
        <Handle type="target" position={Position.Left} id={1} />
    </div>
}

export default memo(EndNode);