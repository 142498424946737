import { toast } from 'react-toastify';

export const InfoToast = (text) => {
    toast.info(text, {
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const ErrorToast = (text) => {
    toast.error(text, {
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}