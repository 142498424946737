import {configureStore} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from "./auth";
import gamesReducer from './games';
import loadingReducer from "./loading";
import sidebarReducer from "./sidebar";

const persistedAuthReducer = persistReducer({
    storage,
    key:"blue-g-auth",
    version:1
}, authReducer)

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        games: gamesReducer,
        sidebar: sidebarReducer,
        loading: loadingReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
export const persistor = persistStore(store)
