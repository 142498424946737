import { createSlice } from "@reduxjs/toolkit";

const sidebarReducer = createSlice({
    name: "sidebar",
    initialState: {
        haveSideBar: false,
        sideBarMinimized: false,
    },
    reducers: {
        SetSidebarAvailability(state, action) {
            state.haveSidebar = action.payload;
        },
        SetSidebarMinimizeState(state, action) {
            state.sidebarMinimize = state.payload;
        }
    }
})

export default sidebarReducer.reducer