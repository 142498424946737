export default function formatBytes(bytes) {
    if (bytes < 1024) return bytes + " byte";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " Kb";
    else if (bytes < 1073741824)
        return (bytes / 1048576).toFixed(3) + " Mb";
    else if (bytes < 1073741824 * 1024)
        return (bytes / 1073741824).toFixed(3) + " Gb";
    else return (bytes / (1073741824 * 1024)).toFixed(3) + " Tb";
}

export const Capitalized = (word) =>  word.charAt(0).toUpperCase()
    + word.slice(1)

export function LimitLong(text, len = 15){
    if (text && text.length > len)
        return `${text.trim().substring(0, len)} ...`;
    return text
}