import {Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";


function Condition({ inx, table, condition, onChange, deleteCondition}){
    const {t} = useTranslation();
    const [_table, setTable] = useState({});

    useEffect(() => {
        if (table) setTable(table)
    }, [table]);

    return <Row>
        {(condition.operation !== "AND" && condition.operation !== "OR") &&  <Col className="mb-3">
            <Form.Group>
                <Form.Label>{t("field")}</Form.Label>
                <Form.Select value={condition.field} onChange={e => onChange("field", e.target.value)}>
                    {table.rows.map(row => <option key={`${condition.key}-${row.name}`} value={row.name}>{row.name}</option>)}
                </Form.Select>
            </Form.Group>
        </Col>}
        <Col className="mb-3">
            <Form.Group>
                <Form.Label>{t("operation")}</Form.Label>
                <Form.Select value={condition.operation} onChange={e => onChange("operation", e.target.value)}>
                    <option value="=">==</option>
                    <option value="!=">!=</option>
                    <option value=">">{'>'}</option>
                    <option value="<">{'<'}</option>
                    <option value=">=">{'>='}</option>
                    <option value="<=">{'<='}</option>
                    <option value="LIKE">Like</option>
                    <option value="AND">And</option>
                    <option value="OR">Or</option>
                </Form.Select>
            </Form.Group>
        </Col>
        {(condition.operation !== "AND" && condition.operation !== "OR") && <Col className="mb-3">
            <Form.Group>
                <Form.Label>{t("value")}</Form.Label>
                <Form.Control type="text" value={condition.value} onChange={e => onChange("value", e.target.value)}/>
            </Form.Group>
        </Col>}
        {condition.parent !== undefined && <Col className="my-auto col-auto">
            <Button variant="outline-danger" onClick={() => deleteCondition(inx)}>Delete</Button>
        </Col>}
    </Row>
}

export default Condition;