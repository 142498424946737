import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import TagInput from "../../../../components/tag-input.component";
import {fetchGameTables} from "../../../../repositories/table.api";
import {ErrorToast} from "../../../../utils/toasts";
import Condition from "./condition.component";
import Sort from "./sort.component";
import Join from "./join.component";

function NewView() {
    const params = useParams();
    const Navigate = useNavigate();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tables, setTables] = useState([]);
    const [table, setTable] = useState({rows: []});
    const [condition, setCondition] = useState([{key:"root", depth:0,  field:"id", operation:"==", value:""}]);
    const [joins, setJoins] = useState([]);
    const joinsRefs = useRef([]);
    const [sorts, setSorts] = useState([]);

    useEffect(() => {
        fetchTables();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault()
        const {base_table,cols, skip, limit} = Object.fromEntries(
            new FormData(e.target).entries()
        );

        for (let i = 0; i < condition.length; i++) {
            for (let j = 0; j < condition.length; j++) {
                if (condition[i].key === condition[j].parent) {
                    if (!condition[i].condition)
                        condition[i].condition = [];
                    if (condition[i].condition.findIndex(c => c.key === condition[j].key)) {
                        condition[i].condition.push(condition[j]);
                    }
                }
            }
        }
        console.log(joinsRefs.current)
    }

    const fetchTables = async () => {
        try {
            setLoading(true);
            let {list: tableList} = await fetchGameTables(params.id, "all")
            setTables(tableList);
            if (tableList.length > 0)
            setTable(tableList[0])
        } catch (e) {
            ErrorToast("Cant load tables")
        } finally {
            setLoading(false)
        }
    }

    const selectTable = async (e) => {
        let selectedTable = tables.find(t => t._id === e.target.value)
        setTable(selectedTable)
    }

    return <Container style={{paddingTop: 40}}>
        <Row>
            <Col sm={12} lg={6} className="mb-5">
                <h2 className="mb-5">{t("new_view")}</h2>
                <form onSubmit={onSubmit}>
                    <Form.Group className="mb-5">
                        <Form.Label>{t("base_table")}</Form.Label>
                        <Form.Select name="base_table" onChange={selectTable}>
                            {tables.map(table => <option key={`base-${table._id}`} value={table._id}>{table.name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <h3>{t("result_cols")}</h3>
                    <div className="mb-5">
                        <TagInput name="cols" defaultValue={table.rows.map(row => row.name).join(",")}/>
                    </div>

                    <h3>{t("conditions")}</h3>
                    <div className="mb-5">
                        {condition.map((cond, inx) => <Col sm={{ span: 12 - cond.depth, offset: cond.depth }} key={`cond-${inx}`} className="mb-3">
                            <Condition
                                inx={inx}
                                table={table}
                                condition={cond}
                                deleteCondition={i => {
                                    setCondition(conds => conds.filter((c, indx) => indx !== i))
                                }}
                                onChange={(field, value) => setCondition(conds => {
                                    conds[inx] = {...conds[inx], [field] : value}
                                    return [...conds]
                                })}
                            />
                            {(cond.operation === "AND" || cond.operation === "OR") && <Col sm={{span: 11, offset:1}} className="d-grid gap-2">
                                <Button onClick={()=>{
                                    setCondition(conds => {
                                        return [...conds.slice(0, inx+1), {depth: cond.depth + 1, key:`${cond.key}-${conds.length+1}`, parent: cond.key, field:"id", operation:"==", value:""}, ...conds.slice(inx+1)]
                                    })
                                }}><i className="material-icons">add</i>{cond.key}</Button>
                            </Col>}
                        </Col>)}
                    </div>

                    <h3>{t("join")}</h3>
                    <div className="mb-5">
                        {joins.map((join, inx) => <Join
                            key={`join-${inx}`}
                            ref={el => joinsRefs.current[inx] = el}
                            tables={tables}
                            index={inx}/>
                        )}
                        <div className="d-grid gap-2">
                            <Button onClick={()=> setJoins(joins => [...joins, {}])}><i className="material-icons">add</i></Button>
                        </div>
                    </div>

                    <h3>{t("sorting")}</h3>
                    <div className="mb-5">
                        {sorts.map((sort, inx) => <Sort table={table} key={`sort-${inx}`}/>)}
                        <div className="d-grid gap-2">
                            <Button onClick={() => {
                                setSorts(sorts => [...sorts, {}])
                            }}><i className="material-icons">add</i></Button>
                        </div>
                    </div>

                    <h3>{t("pagination")}</h3>
                    <Row className="mb-5">
                        <Col>
                            <Form.Group>
                                <Form.Label>{t("skip")}</Form.Label>
                                <Form.Control type="number" defaultValue={0} name="skip"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>{t("limit")}</Form.Label>
                                <Form.Control type="number" defaultValue={50} name="limit"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type="submit" variant="success">{t("create")}</Button>
                </form>
            </Col>
        </Row>
    </Container>
}

export default NewView;