import React from 'react';
import {Container, Navbar, Nav, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function LandingNavbar(){
    const {t} = useTranslation();
    return <Navbar bg="transparent" expand="md">
        <Container>
            <Navbar.Brand href="/"><img src="/images/logo.png" alt="dynamicpixels logo"/> </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link as={NavLink} activeClassName="active" to="/">{t("home")}</Nav.Link>
                    <Nav.Link as={NavLink} activeClassName="active" to="/services">{t("services")}</Nav.Link>
                    <a className="nav-link" target="_blank" activeClassName="active"
                       href="https://docs.dynamicpixels.dev" rel="noreferrer">{t("docs")}</a>
                    <Nav.Link as={NavLink} activeClassName="active" to="/pricing">{t("pricing")}</Nav.Link>
                    {/*<Nav.Link as={NavLink} activeClassName="active" to="/contact">{t("contact")}</Nav.Link>*/}
                </Nav>
                <Nav>
                    <Nav.Link as={NavLink} to="/login">
                        <Button variant="light">{t("signin")}</Button>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}

export default LandingNavbar;