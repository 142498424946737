import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import LandingNavbar from "./navbar.component";
import React from "react";
import Footer from "./footer.component";
import "./style.css";

export default function PrivacyPolicy() {
    const {t} = useTranslation();

    return <>
        <Container>
            <LandingNavbar/>
        </Container>
        <div className="purpose">
            <Container>
                <Row className="section mb-3">
                    <Col className="hero-bc"></Col>
                    <Col>
                        <h2>Privacy Policy</h2>
                    </Col>
                    <Col sm={12} lg={4} className="hero-notes-bc">
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row className="mb-6">
                <article className="mb-5">
                    <p>
                        <h3>Introduction</h3>

                        Welcome to DynamicPixels ("we", "us", or "our"). We are committed to protecting your privacy and
                        ensuring that your personal information is handled in a safe and responsible manner. This
                        Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
                        use our Platform as a Service (PaaS) cloud service. By accessing or using our service, you agree
                        to the terms of this Privacy Policy.

                    </p>
                    <p>
                        <h3>Information We Collect</h3>

                        <h4>Personal Information</h4>

                        When you register for an account or use our Service, we may collect personal information,
                        including but not limited to:

                        <ul>
                            <li><b>Identity Information:</b> Name, username, or similar identifiers.</li>
                            <li><b>Contact Information:</b> Email address, phone number, and postal address.</li>
                            <li><b>Financial Information:</b> Payment card details, bank account information.</li>
                            <li><b>Technical Information:</b> IP address, browser type and version, time zone setting,
                                browser plug-in types and versions, operating system and platform, and other technology
                                on the devices you use to access this service.
                            </li>
                            <li><b>Profile Information:</b> Your username and password, preferences, feedback, and
                                survey responses.
                            </li>
                            <li><b>Usage Data:</b> Information about how you use our services.</li>
                            <li><b>Marketing and Communications Data:</b> Your preferences in receiving marketing from
                                us and your communication preferences.
                            </li>
                        </ul>
                        <h4>Non-Personal Information</h4>

                        We may collect, use, and share aggregated data such as statistical or demographic data.
                        Aggregated data may be derived from your personal data but is not considered personal data in
                        law as this data does not directly or indirectly reveal your identity.

                        <h3>How We Collect Information</h3>
                        We use different methods to collect data from and about you including:

                        <ul>
                            <li><b>Direct interactions:</b> You may give us your identity, contact, and financial data
                                by filling
                                in forms or by corresponding with us by post, phone, email, or otherwise.
                            </li>
                            <li><b>Automated technologies or interactions:</b> As you interact with our service, we may
                                automatically collect technical data about your equipment, browsing actions, and
                                patterns.
                                We collect this personal data by using cookies, server logs, and other similar
                                technologies.
                            </li>
                            <li><b>Third parties or publicly available sources:</b> We may receive personal data about
                                you from
                                various third parties and public sources.
                            </li>
                        </ul>

                        <h3>How We Use Your Information</h3>

                        We use the information we collect in the following ways:

                        <ul>
                            <li>To provide, operate, and maintain our service</li>
                            <li>To improve, personalize, and expand our service</li>
                            <li>To understand and analyze how you use our service</li>
                            <li>To develop new products, services, features, and functionality</li>
                            <li>To process your transactions and manage your orders</li>
                            <li>To communicate with you, either directly or through one of our partners, including for
                                customer service, to provide you with updates and other information relating to the
                                service,
                                and for marketing and promotional purposes
                            </li>
                            <li>To send you emails</li>
                            <li>To find and prevent fraud</li>
                            <li>To comply with legal obligations</li>
                        </ul>

                        <h3>Disclosure of Your Information</h3>
                        We may share your personal information in the following situations:

                        <ul>
                            <li><b>With service providers:</b> We may share your personal information with service
                                providers to
                                monitor and analyze the use of our service, to show advertisements to you to help
                                support
                                and maintain our service, for payment processing, to contact you.
                            </li>
                            <li><b>For business transfers:</b> We may share or transfer your personal information in
                                connection
                                with, or during negotiations of, any merger, sale of company assets, financing, or
                                acquisition of all or a portion of our business to another company.
                            </li>
                            <li><b>With affiliates:</b> We may share your information with our affiliates, in which case
                                we will
                                require those affiliates to honor this Privacy Policy.
                            </li>
                            <li><b>With business partners:</b> We may share your information with our business partners
                                to offer
                                you certain products, services, or promotions.
                            </li>
                            <li><b>With other users:</b> When you share personal information or otherwise interact in
                                the public
                                areas with other users, such information may be viewed by all users and may be publicly
                                distributed outside.
                            </li>
                            <li><b>With your consent:</b> We may disclose your personal information for any
                                other purpose with your
                                consent.
                            </li>
                            <li><b>For legal reasons:</b> We may disclose your information if we are
                                required to do so by law or in
                                response to valid requests by public authorities.
                            </li>
                        </ul>

                        <h3>Security of Your Information</h3>
                        We use administrative, technical, and physical security measures to help protect your
                        personal information. While we have taken reasonable steps to secure the personal
                        information you provide to us, please be aware that despite our efforts, no security
                        measures are perfect or impenetrable, and no method of data transmission can be guaranteed
                        against any interception or other type of misuse.
                    </p>
                </article>
            </Row>
        </Container>

        <Footer/>
    </>
}