import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Sort({table}){
    const {t} = useTranslation();

    return <Row>
        <Col className="mb-3">
            <Form.Group>
                <Form.Label>{t("field")}</Form.Label>
                <Form.Select>
                    {table.rows.map(row => <option value={row.name}>{row.name}</option>)}
                </Form.Select>
            </Form.Group>
        </Col>
        <Col className="mb-3">
            <Form.Group>
                <Form.Label>{t("order")}</Form.Label>
                <Form.Select>
                    <option value="ASC">Ascending</option>
                    <option value="DESC">Descending</option>
                </Form.Select>
            </Form.Group>
        </Col>
    </Row>
}

export default Sort;