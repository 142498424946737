import { createSlice } from "@reduxjs/toolkit";

const loadingReducer = createSlice({
    name: "loading",
    initialState: {
        loading: false
    },
    reducers: {
        SetLoadingState(state, action) {
            state.loading = action.payload
        }
    }
})

export const actions = loadingReducer.actions;

export default loadingReducer.reducer