import React, {useCallback, useState} from 'react';
import {Button, ButtonGroup, Col, Container, Dropdown, Form} from "react-bootstrap";
import ReactFlow, {addEdge, Background, Panel, NodeToolbar, NodeResizer, Controls, MiniMap, useEdgesState, useNodesState} from 'reactflow';
import 'reactflow/dist/style.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TriggerNode from "./trigger.node";
import EndNode from "./end.node";
import ActionNode from "./action.node";
import DecisionNode from "./decision.node";

const initialNodes = [
    {id: '1', type: "trigger", position: {x: 0, y: 0}, data: {label: '1'}},
    {id: '2', type: "end", position: {x: 0, y: 100}, data: {label: '2'}},
];
const initialEdges = [{id: 'e1-2', source: '1', target: '2'}];

const nodeTypes = {
    trigger: TriggerNode,
    end: EndNode,
    action: ActionNode,
    decision: DecisionNode
}

function Journey() {
    const {t} = useTranslation();
    const params = useParams();

    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("Unknown Journey");
    const [editName, setEditName] = useState(false);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );
    
    const addNode = (type) =>{
        setNodes(ns => [...ns, {
            id: `${ns.length + 1}`,
            type: type,
            position: {
                x: 0,
                y: 300
            },
            data: {
                label: `${ns.length + 1}`
            }
        }])
    }

    const Save = ()=>{
        console.log(nodes, edges)
    }

    return <>
        <Container style={{marginTop: 30, width: '100vw'}}>
            <Col sm={12} className="mb-3 d-flex justify-content-between align-items-center">
                <div style={{display:"flex", justifyContent:"start"}}>
                    {!editName ?
                        <h4 onClick={()=> setEditName(true)} style={{cursor:"pointer"}}>{name}</h4>
                        :
                        <>
                        <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
                            <Button onClick={() => setEditName(false)}>Ok</Button>
                        </>
                    }
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"end"}}>
                    <Button onClick={Save}>{t("save")}</Button>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary">
                            {t("add_node")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => addNode("action")}>{t("add_action")}</Dropdown.Item>
                            <Dropdown.Item onClick={() => addNode("decision")}>{t("add_decision")}</Dropdown.Item>
                            <Dropdown.Item onClick={() => addNode("trigger")}>{t("add_trigger")}</Dropdown.Item>
                            <Dropdown.Item onClick={() => addNode("end")}>{t("add_end")}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle variant="success">
                            <i className="fa-solid fa-play"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {nodes.map(node => node.type==="trigger" && <Dropdown.Item href="#/action-1">Run: {node.data.label}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Col>
            <Col sm={12} style={{ height: '70vh'}} className="mb-5">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                >

                    {/*<NodeToolbar/>*/}
                    {/*<NodeResizer/>*/}
                    <Background variant="dots" gap={30} size={1}/>
                </ReactFlow>
            </Col>
        </Container>
    </>
}

export default Journey;