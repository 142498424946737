export const baseUrl = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:3001/api";
export const socketUrl = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_API_ENDPOINT : "localhost:3000";
export const deployerUrl = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_DEPLOYER_ENDPOINT : "https://deployer.dynamicpixels.dev";
export const serviceUrl = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_COMPUTING_ENDPOINT : "http://127.0.0.1:3003";

export const categories = [
    "Action",
    "Adventure",
    "Puzzle",
    "Role-Playing",
    "Simulation",
    "Strategy",
    "Sport",
    "MMO",
    "Open-World",
    "Study"
]

export const prices = {
    node: 0.09,
    db_tx: 0.000004,
    db_alloc: 0.0000002,
    storage: 0.001,
    packet: 0.000000025,
}

export const recapcha ={
    siteKey: "6LcEQ8kpAAAAAGMtnOXgjgUCnNGcP2KDOregm7X3"
}