import React, {useEffect, useState} from 'react';
import {Badge, Form} from "react-bootstrap";

function TagInput({key, name, defaultValue}) {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (defaultValue && defaultValue.length > 0)
            setTags((defaultValue).split(","))
    }, [defaultValue]);
    const onKeyDown = (e) => {
        const {key} = e;
        const trimmedInput = input.trim();

        if (key === ',' && trimmedInput.length && !tags.includes(trimmedInput)) {
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }
    };

    const onDrop = (item) => {
        setTags(tags.filter(t => t !== item))
    }

    const onChange = (e) => {
        const {value} = e.target;
        setInput(value);
    };

    return <Form.Group key={key} className="mb-3 input-tag-container">
        {name && <Form.Label>{name}: </Form.Label>}
        <Form.Control type="text" name={name} style={{display:"none"}} value={tags.join(",")}/>
        <div>
        {tags.map((tag, inx) => <Badge key={`${key}-tag-${inx}`} className="input-tag mb-2" style={{marginRight: 5}}>
            {tag}
            <i className="fa-solid fa-xmark" onClick={()=> onDrop(tag)}></i>
        </Badge>)}
        </div>
        <Form.Control
            type="text"
            maxLength={1000}
            value={input}
            placeholder="Enter a tag, divide by ,"
            onKeyDown={onKeyDown}
            onChange={onChange}
        />
    </Form.Group>
}

export default TagInput;