import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../i18n/en";

const resources = {
  en: en,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  debug: process.env.REACT_APP_MODE !== "production",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
