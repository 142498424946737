import React, { useState} from 'react';
import {useTranslation} from "react-i18next";

function ErrorPage(props) {
    const {t} = useTranslation();

    return <div className="error-page">
        <i className="fa-solid fa-triangle-exclamation"></i>
        <h4>{t("panic_error")}</h4>
        <small>{t("panic_note")}</small>
    </div>
}

export default ErrorPage;